<!-- 个人资料 -->
<template>
  <div class="personal">
    <!-- 导航标签 -->
    <div class="flex biao align-items">
      <!-- <div>{{ "当前位置 : " }}</div>
      <div style="opacity: 0">{{ "0" }}</div>
      <div class="">{{ "首页" }}</div>
      <div class="zuojian">
        <img
          src="../../assets/img/xinban/zuojian.png"
          class="w-full h-full"
          alt=""
        />
      </div>
      <div class="yanse">{{ ming }}</div> -->
    </div>
    <!-- 发布主题与撤回 -->
    <div class="chehui flex space-between align-items">
      <div class="flex">
        <!-- <el-button type="primary" @click="fabu">发布留言</el-button> -->
      </div>
      <div class="flex align-items che cursor_pointer" @click="fanhui">
        <!-- <img src="../../assets/img/xinban/fanhui.png" class="huitui" alt="" />
        <div>{{ "返回" }}</div> -->
      </div>
    </div>
    <!-- 个人资料 -->
    <div class="geren">
      <div class="flex xiugai align-items">
        <div @click="qshehzi" class="flex align-items cursor_pointer">
          <img src="../../assets/img/xinban/fun.png" class="shezhi" alt="" />
          <div class="xiugaixinxi">
            {{ fyz == 0 ? "账号设置" : "留言管理" }}
          </div>
        </div>
      </div>
      <div class="flex w-full xinxi">
        <div class="touxiang">
          <img
            v-if="zhuti == null ? '' : zhuti.image != ''"
            :src="
              zhuti == null
                ? '../../assets/img/one/weizhiyonghu.png'
                : zhuti.image
            "
            class="w-full h-full"
            alt=""
          />
          <img
            v-else
            src="../../assets/img/one/weizhiyonghu.png"
            class="w-full h-full"
            alt=""
          />
        </div>
        <div class="ziliao">
          <div class="fontone displayFlex row_align_end">{{ zhuti == null ? "" : zhuti.name }} <div v-if="zhuti != null" style="margin-left: 20px;color: black;">ID:{{  zhuti.user_id }}</div></div>
          <div class="flex align-items" style="margin-bottom: 20px">
            <!-- 主题 -->
            <div
              class="flex column align-items fontTwo cursor_pointer"
              @click="tiaoxia(0)"
            >
              <div class="dzi">{{ zhuti == null ? 0 : zhuti.zhuti }}</div>
              <div class="fontThree">{{ "主题" }}</div>
            </div>
            <div class="xian"></div>
            <!-- 回帖 -->
            <div
              class="flex column align-items fontTwo cursor_pointer"
              @click="tiaoxia(1)"
            >
              <div class="dzi">{{ zhuti == null ? 0 : zhuti.huitie }}</div>
              <div class="fontThree">{{ "回帖" }}</div>
            </div>
            <div class="xian"></div>
            <!-- 收藏 -->
            <div
              class="flex column align-items fontTwo cursor_pointer"
              @click="tiaoxia(2)"
            >
              <div class="dzi">{{ zhuti == null ? 0 : zhuti.collection }}</div>
              <div class="fontThree">{{ "收藏" }}</div>
            </div>
            <div class="xian"></div>
            <!-- 关注 -->
            <div
              class="flex column align-items fontTwo cursor_pointer"
              @click="tiaoxia(3)"
            >
              <div class="dzi">{{ zhuti == null ? 0 : zhuti.follow }}</div>
              <div class="fontThree">{{ "关注" }}</div>
            </div>
            <div class="xian"></div>
            <!-- 获赞 -->
            <div
              class="flex column align-items fontTwo cursor_pointer"
              @click="tiaoxia(4)"
            >
              <div class="dzi">{{ zhuti == null ? 0 : zhuti.fabulous }}</div>
              <div class="fontThree">{{ "点赞" }}</div>
            </div>
          </div>
          <!-- 性别,手机,邮箱 -->
          <!-- <div class="flex align-items meaage">
            <div class="flex mr40">
              <div>{{ "性别:" }}</div>
              <div class="opacity">0</div>
              <div>
                {{ user == null ? "男" : user.gender == 0 ? "女" : "男" }}
              </div>
            </div>
            <div class="flex mr40">
              <div>{{ "手机号:" }}</div>
              <div class="opacity">0</div>
              <div>{{ user == null ? "" : user.mobile }}</div>
            </div>
            <div class="flex mr60">
              <div>{{ "邮箱:" }}</div>
              <div class="opacity">0</div>
              <div>{{ user == null ? "" : user.email }}</div>
            </div>
            <div class="flex">
              <div>{{ "绑定第三方账号:" }}</div>
              <div class="opacity">00</div>
              <div
                class="flex align-items mr50"
                v-if="user == null ? 0 : user.third.wechatpc.isbind == 0"
              >
                <div class="disanfang">
                  <img
                    src="../../assets/img/one/weixin.png"
                    class="w-full h-full"
                    alt=""
                  />
                </div>
                <div>{{ "未绑定" }}</div>
              </div>
              <div class="flex align-items mr50" v-else>
                <div class="disanfang">
                  <img
                    src="../../assets/img/one/weixin.png"
                    class="w-full h-full"
                    alt=""
                  />
                </div>
                <div>{{ "已绑定" }}</div>
              </div>
              <div
                class="flex align-items cursor_pointer"
                v-if="user == null ? 0 : user.third.weibo.isbind == 0"
              >
                <div class="disanfang">
                  <img
                    src="../../assets/img/one/weibo.png"
                    class="w-full h-full"
                    alt=""
                  />
                </div>
                <div>{{ "未绑定" }}</div>
              </div>
              <div class="flex align-items" v-else>
                <div class="disanfang">
                  <img
                    src="../../assets/img/one/weibo.png"
                    class="w-full h-full"
                    alt=""
                  />
                </div>
                <div>{{ "已绑定" }}</div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 发帖记录 -->
    <div>
      <div v-if="fyz != 1">
        <div class="fatie flex">
          <div class="zuo">
            <div class="moban_tou flex align-items">
              <img
                src="../../assets/img/xinban/fenlei.png"
                class="icon"
                alt=""
              />
              <div class="fontOne">{{ "常用导航" }}</div>
            </div>
            <div class="liebiao">
              <div
                class="moban"
                @click="qiehuan(index)"
                @mouseover="jinru(index)"
                @mouseout="yichu(index)"
                :class="{ timu: timuNum == index, acti: xuanuNum == index }"
                v-for="(item, index) in liebiao"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <div class="you">
            <post v-if="timuNum == 0" ref="post"></post>
            <backejecta v-else-if="timuNum == 2" ref="backejecta"></backejecta>
            <attention v-else-if="timuNum == 3" ref="attention"></attention>
            <collect v-else-if="timuNum == 4" ref="collect"></collect>
            <dainzan v-else-if="timuNum == 5" ref="dainzan"></dainzan>
            <footprint v-else-if="timuNum == 6" ref="footprint"></footprint>
            <draft v-else-if="timuNum == 7" ref="draft"></draft>
          </div>
        </div>
        <!-- 页码 -->
        <div class="yema">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            background
            :page-sizes="tiaomu"
            :page-size="100"
            layout=" prev, pager, next, sizes, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 账号设置 -->
      <div v-else>
        <sz ref="sz"></sz>
      </div>
    </div>
  </div>
</template>

<script>
import post from "./postMessage/index.vue"; // 发帖列表
import collect from "./collect/index.vue"; // 我的收藏
import attention from "./attention/index.vue"; // 我的关注
import backejecta from "./backejecta/index.vue"; // 我的回帖
import draft from "./draft/index.vue"; // 草稿箱
import dainzan from "./dianzan/index.vue"; // 我的点赞
import footprint from './footprint/index.vue' // 浏览足迹
import API from "@/api/modules/geren";
import Cookies from "js-cookie";
import sz from "../editing/index.vue"; // 修改账号设置
import Bus from "@/assets/ligature";
export default {
  components: {
    post,
    collect,
    attention,
    backejecta,
    draft,
    dainzan,
    sz,
    footprint
  },
  data() {
    return {
      // 发帖列表.我的回帖.我的关注.我的收藏.我的点赞.草稿箱
      liebiao: [
        "发帖列表",
        "我要发帖",
        "我的回帖",
        "我的关注",
        "我的收藏",
        "我的点赞",
        "浏览足迹",
        "草稿箱",
      ],
      timuNum: 0,
      xuanuNum: 0,
      page: 1, //当前页码
      zongye: 10, //总条目数
      total: 0,
      user: null, //用户信息
      zhuti: null, //主题信息
      tiaomu: [10, 20],
      fyz: 0,
      ming: "留言管理",
    };
  },
  mounted() {
    localStorage.setItem("path", this.$route.path);
    // 先判定是否有token 如果为空
    // window.localStorage.setItem('sendebang', a)
    let aaa = window.localStorage.getItem("sendebang");
    // window.localStorage.removeItem('sendebang')
    if (aaa == undefined) {
      this.$store.state.path = this.$route.path;
      localStorage.setItem("path", this.$route.path);
      this.$router.push("/login");
      return;
    }
    // 判定是否为扫码绑定
    // 判定是否是扫码后的回调
    let bd = sessionStorage.getItem("bd");
    // 如果是绑定登录则跳入账号设置
    if (bd != null) {
      this.$store.state.shezhi = 1;
      // this.Scancode();
    }
    if (this.$store.state.shezhi == 1) {
      this.fyz = 1;
      this.ming = "账号设置";
      document.title = '账号设置'
    } else {
      this.fyz = 0;
      this.ming = "留言管理";
      document.title = '留言管理'
    }
    this.yonghu();
    document.getElementsByClassName(
      "el-pagination__jump"
    )[0].childNodes[0].nodeValue = "跳至";
  },
  watch: {
    "$store.state.shezhi": function (vla, old) {
      // console.log(vla);
      if (vla == 1) {
        this.fyz = 1;
        this.ming = "账号设置";
        document.title = '账号设置'
      } else {
        this.fyz = 0;
        this.ming = "留言管理";
        document.title = '留言管理'
      }
    },
  },
  created() {
    // 搜索关键字:互切换
    Bus.$on("hqieh", (num) => {
      if (num == 1) {
        this.fyz = 1;
        this.ming = "账号设置";
      } else {
        this.fyz = 0;
        this.ming = "留言管理";
      }
    });
  },
  methods: {
    // 进入
    jinru(num) {
      this.xuanuNum = num;
    },
    yichu(num) {
      this.xuanuNum = 99;
    },
    // 点上面跳下面
    tiaoxia(num) {
      this.fyz = 0;
      if (num == 0) {
        this.timuNum = num;
      } else if (num == 1) {
        this.timuNum = 2;
      } else if (num == 2) {
        this.timuNum = 4;
      } else if (num == 3) {
        this.timuNum = 3;
      } else if (num == 4) {
        this.timuNum = 5;
      }
    },
    // 解绑是否存在
    xianshen() {
      if (this.user == null) {
        return;
      }
      if (this.user.third.wechatpc.isbind == 0) {
        if (this.user.third.weibo.isbind == 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    // 返回首页
    fanhui() {
      this.jump("/", this.$store.state.loginMessage);
    },
    // 跳向其设置
    qshehzi() {
      if (this.fyz == 0) {
        this.fyz = 1;
        this.ming = "账号设置";
        document.title = '账号设置'
      } else {
        this.fyz = 0;
        this.ming = "留言管理";
        document.title = '留言管理'
      }

      //   this.jumps("editing", this.$store.state.loginMessage);
    },
    // 进入发布页面
    fabu() {
      this.jumps("issue", this.$store.state.loginMessage);
    },
    // 获取total
    totals(num) {
      this.total = num;
      // console.log(this.total, "22200");
    },
    async yonghu() {
      // let res = await API.user().then((res) => {
      //   if (res.code == 1) {
      //     this.user = res.data;
      //   }
      // });
      let obj = await API.userHomepage().then((res) => {
        if (res.code == 1) {
          this.zhuti = res.data;
        }
      });
    },
    // 切换列表
    qiehuan(num) {
      this.total = 0;
      this.tiaomu = [10, 10];
      // 利用0.5秒的延迟让其修改条目数,页面无法发现
      let timer = setTimeout(() => {
        this.tiaomu = [10, 20];
      }, 500);
      this.page = 1;
      this.zongye = 10;
      this.timuNum = num;
      if (num == 1) {
        this.fabu();
      }
    },
    // 切换条目
    handleSizeChange(val) {
      if (this.timuNum == 0) {
        this.$refs.post.zongye(val);
      } else if (this.timuNum == 7) {
        this.$refs.draft.zongye(val);
      } else if (this.timuNum == 4) {
        this.$refs.collect.zongye(val);
      } else if (this.timuNum == 3) {
        this.$refs.attention.zongye(val);
      } else if (this.timuNum == 2) {
        this.$refs.backejecta.zongye(val);
      } else if (this.timuNum == 5) {
        this.$refs.dainzan.zongye(val);
      }else if (this.timuNum == 6) {
        // 浏览足迹
        this.$refs.footprint.zongye(val);
      }
      // console.log(`每页 ${val} 条`);
    },
    // 切换页码
    handleCurrentChange(val) {
      if (this.timuNum == 0) {
        this.$refs.post.pages(val);
      } else if (this.timuNum == 7) {
        this.$refs.draft.pages(val);
      } else if (this.timuNum == 4) {
        this.$refs.collect.pages(val);
      } else if (this.timuNum == 3) {
        this.$refs.attention.pages(val);
      } else if (this.timuNum == 2) {
        this.$refs.backejecta.pages(val);
      } else if (this.timuNum == 5) {
        this.$refs.dainzan.pages(val);
      }else if (this.timuNum == 6) {
        // 浏览足迹
        this.$refs.footprint.pages(val);
      }
      // console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style scoped lang="scss">
.personal {
  width: 1200px;
}

.biao {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #191a1c;
  letter-spacing: 0;
  font-weight: 400;
  margin: 16px 0 16px 0;

  .zuojian {
    width: 18px;
    height: 18px;
    margin: 0 3px;
  }

  .yanse {
    color: #0653ac;
  }
}

.chehui {
  ::v-deep(.el-button--primary) {
    width: 110px;
    height: 44px;
    background: #2670c5;
    border-radius: 2px;
    border: 0px solid red;
    margin-right: 17px;
  }

  ::v-deep(.el-button--info) {
    width: 82px;
    height: 44px;
    background: #86898d;
    border-radius: 2px;
  }

  .che {
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #2670c5;
    letter-spacing: 0;
    font-weight: 400;

    .huitui {
      width: 15.69px;
      height: 13.07px;
      margin-right: 6.15px;
    }
  }
}

.geren {
  width: 100%;
  background: #f4f8fd;
  border: 1px solid #d6dbe3;
  margin-top: 15px;
  padding: 15px 15px 29px 31px;
  margin-bottom: 14px;

  .xiugai {
    width: 100%;
    justify-content: flex-end;

    .shezhi {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }
}

.xinxi {
  .touxiang {
    width: 110px;
    height: 110px;
    background: #ffffff;
    border: 1px solid #d6dbe3;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 28px;
  }

  .ziliao {
    flex: 1;
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .fontone {
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #34348f;
      letter-spacing: 0;
      text-align: justify;
      line-height: 21px;
      font-weight: 500;
    }

    .fontTwo {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #27262f;
      letter-spacing: 0;
      font-weight: 400;
    }

    .fontThree {
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #484c51;
      letter-spacing: 0;
      text-align: justify;
      line-height: 21px;
      font-weight: 700;
    }

    .xian {
      width: 0.5px;
      height: 16px;
      border-left: 1px solid #d6dbe3;
      margin: 0 28px 0 30px;
    }
  }

  .meaage {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #484c51;
    letter-spacing: 0;
    font-weight: 400;

    .mr40 {
      margin-right: 40px;
    }

    .disanfang {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }

    .mr50 {
      margin-right: 50px;
    }

    .mr60 {
      margin-right: 60px;
    }
  }
}

.fatie {
  width: 100%;
  background: #ffffff;
  border: 1px solid #d6dbe3;

  .zuo {
    // width: 226px;
    width: 150px;
    border-right: 1px solid #d6dbe3;

    .moban_tou {
      padding: 9px 0 9px 0;
      justify-content: center;

      .icon {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }

      .fontOne {
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #0653ac;
        letter-spacing: 0;
        font-weight: 500;
      }
    }

    .timu {
      background: #3eacdd;
      font-family: PingFangSC-Regular;
      font-size: 18px;
      color: #36383a;
      letter-spacing: 0;
      font-weight: 700;
      text-align: center;
      color: #fff;
    }

    .liebiao {
      margin: 0px 0 29px 0;
      text-align: center;
      font-family: PingFangSC-Regular;
      font-size: 18px;
      color: #36383a;
      letter-spacing: 0;
      font-weight: 400;

      .moban {
        padding: 14px 0 13px 0;
        cursor: pointer;
      }
    }
  }

  .you {
    flex: 1;
  }
}

.yema {
  margin: 30px 0 30px 0;
  display: flex;
  justify-content: flex-end;

  ::v-deep(.el-pager) {
    .number {
      background: #fff;
      border: 0.5px solid #d6dbe3;
    }
  }

  ::v-deep(.el-pagination__jump) {
    margin-left: 0 !important;
  }
}

.xiugaixinxi {
  margin-left: 5px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #484c51;
  letter-spacing: 0;
  font-weight: 400;
}

.jiebang {
  margin-left: 20px;
  cursor: pointer;

  &:hover {
    color: #c90e0e;
  }
}
.acti {
  font-weight: 700;
  color: #2670c5;
}
.dzi {
  font-size: 16px;
}
.displayFlex {
	display: flex;
	flex-direction: row;
}
.displayFlexReverse {
	display: flex;
	flex-direction: row-reverse;
}
.displayFlex_column {
	display: flex;
	flex-direction: column;
}
.w_690{
	width: 92%;
}
.w_100{
	width: 100%;
}
.align_items {
	align-items: center;
}

.space_between {
	justify-content: space-between;
}
.row_align_end {
	align-items: flex-end;
}

</style>